import { Container, Row, Col, Visible } from "react-grid-system";
import { RouteComponentProps } from "@reach/router";
import React, { FunctionComponent } from "react";
import { graphql, navigate } from "gatsby";
import { useIntl } from "react-intl";

// @ts-ignore
import handleViewport from "react-in-viewport";
import {
  HandleViewportProps,
  FadeSlideAnimationContainer
} from "../../styles/animations";

import Preview from "../../components/common/Preview";
import { Layout } from "../../components";

import styles from "./styles";

interface ServicesTemplateProps
  extends HandleViewportProps,
    RouteComponentProps {
  data: {
    prismicServicesPage: {
      data: {
        background_image: { alt: string; url: string };
        services: Array<{
          service_title: { text: string };
          service_description: { html: string };
        }>;
        seo_title?: string;
        seo_description?: string;
        seo_image?: { alt: string; url: string };
        canonical_url?: {
          url: string;
        };
      };
    };
    background: {
      publicURL: string;
    };
  };
}

const ServicesTemplate: FunctionComponent<ServicesTemplateProps> = ({
  data,
  forwardedRef
}) => {
  const { locale, formatMessage } = useIntl();

  const {
    PageTitle,
    PageSubtitle,
    ServiceFieldset,
    ServiceTitle,
    ServiceDescription,
    ServiceImgClipper,
    ServiceImg,
    MoreServicesButton
  } = styles;
  return (
    <Layout
      metaTitle={
        data.prismicServicesPage.data.seo_title ||
        formatMessage({ id: "pages.services.meta-title" })
      }
      metaDescription={
        data.prismicServicesPage.data.seo_description ||
        formatMessage({ id: "pages.services.meta-description" })
      }
      metaKeywords={
        data.prismicServicesPage.data.seo_keywords ||
        formatMessage({ id: "pages.services.meta-keywords" })
      }
      metaImage={data.prismicServicesPage.data.seo_image}
      metaURL={data.prismicServicesPage.data.canonical_url?.url}
    >
      <div
        style={{
          backgroundPosition:
            "right -500px top -500px, left -500px bottom -500px",
          backgroundImage: `url(${data.background.publicURL}), url(${data.background.publicURL})`,
          backgroundSize: "100%",
          backgroundRepeat: "no-repeat"
        }}
      >
        <Container ref={forwardedRef}>
          <Row style={{ marginTop: "40px", marginBottom: "226px" }}>
            <Col xs={12} style={{ marginBottom: "76px" }}>
              <PageTitle>
                {formatMessage({ id: "specialized-services" }).split(" ")[0]}
              </PageTitle>
              <PageSubtitle>
                {formatMessage({ id: "specialized-services" }).split(" ")[1]}
              </PageSubtitle>
            </Col>
            <Col xs={12} md={10} offset={{ md: 1 }}>
              <Row justify="center">
                {data.prismicServicesPage.data.services.map(
                    (service, index) => (
                      <Col
                        xs={12}
                        md={6}
                        lg={4}
                        key={`service#${index}`}
                        style={{ marginBottom: "64px" }}
                      >
                        <FadeSlideAnimationContainer duration={1 + 0.5 * index}>
                          <ServiceFieldset>
                            <ServiceTitle>
                              {service.service_title.text}
                            </ServiceTitle>
                            <ServiceDescription
                              dangerouslySetInnerHTML={{
                                __html: service.service_description.html
                              }}
                            />
                          </ServiceFieldset>
                        </FadeSlideAnimationContainer>
                      </Col>
                    )
                  )}
                </Row>
            </Col>
            <Col xs={12} style={{ textAlign: "center" }}>
              <Visible xs sm>
                <MoreServicesButton
                  onClick={() => navigate(`/${locale}/#services`)}
                >
                  {formatMessage({ id: "other-services" })}
                </MoreServicesButton>
              </Visible>
            </Col>
          </Row>
        </Container>
      </div>
      <div style={{ width: "100%", position: "relative" }}>
        <Visible md lg xl>
          <MoreServicesButton
            style={{ marginLeft: "140px" }}
            onClick={() => navigate(`/${locale}/#services`)}
          >
            {formatMessage({ id: "other-services" })}
          </MoreServicesButton>
        </Visible>
        <ServiceImgClipper>
          <ServiceImg
            alt={data.prismicServicesPage.data.background_image.alt}
            src={data.prismicServicesPage.data.background_image.url}
          />
        </ServiceImgClipper>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query($lang: String!) {
    prismicServicesPage(lang: { eq: $lang }) {
      data {
        background_image {
          alt
          url
        }
        services {
          service_title {
            text
          }
          service_description {
            html
          }
        }
        seo_title
        seo_description
        seo_keywords
        seo_image {
          alt
          url
        }
        canonical_url {
          url
        }
      }
    }
    background: file(
      sourceInstanceName: { eq: "static" }
      relativePath: { eq: "images/Background-single-transparent.png" }
    ) {
      publicURL
    }
  }
`;

export default handleViewport(Preview(ServicesTemplate));
