import styled from "@emotion/styled";

const PageTitle = styled.h1`
  font-family: Roboto;
  font-size: 42px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: 0;
`;

const PageSubtitle = styled.h1`
  font-family: Roboto;
  font-size: 42px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: normal;
  color: #f38f1d;
`;

const ServiceFieldset = styled.fieldset`
  height: 100%;
  margin-bottom: 0;
  min-height: 300px;
  background-color: white;
  border: solid 3px #f38f1d;
`;

const ServiceTitle = styled.legend`
  font-family: Roboto;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.19px;
  color: #000000;
  padding-left: 20px;
  padding-right: 20px;
`;

const ServiceDescription = styled.p`
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: 0;
  margin-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
`;

const ServiceImgClipper = styled.div`
  width: 100%;
  height: 640px;
  overflow: hidden;
  transform: skewY(-10deg);
  margin-bottom: -131px;
`;

const ServiceImg = styled.img`
  width: 100%;
  height: 624px;
  object-fit: cover;
  transform: skewY(10deg) translateY(-115px);
`;

const MoreServicesButton = styled.button`
  outline: none;
  color: #000000;
  font-size: 14px;
  padding: 0 16px;
  cursor: pointer;
  min-height: 45px;
  font-weight: bold;
  text-align: center;
  font-style: normal;
  line-height: normal;
  font-family: Roboto;
  font-stretch: normal;
  letter-spacing: 1.5px;
  border: solid 1px #f38f1d;
  background-color: #ffffff;
  text-transform: uppercase;
`;

const styles = {
  PageTitle,
  PageSubtitle,
  ServiceFieldset,
  ServiceTitle,
  ServiceDescription,
  ServiceImgClipper,
  ServiceImg,
  MoreServicesButton
};

export default styles;
